import { usePdsBootContext } from "../../common/PdsBootContext"
import { ViewState } from "../../common/ViewState"
import { showErrorToast } from "../../common/errorToastHelper"
import { browserLanguage, hasMessage } from "../../common/global"
import { EmptyView } from "../../components/emptyview/EmptyView"
import { ViewHeadline } from "../../components/headlines/viewheadline/ViewHeadline"
import { DefaultCardViewContentWrapper } from "../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { WidgetWrapper } from "../../components/layouts/widgetwrapper/WidgetWrapper"
import { SortableList, SortableListItem } from "../../components/sortablelist/SortableList"
import { UserAuthority } from "../../generated/pdsapi"
import { useToast } from "@finder/ui-kit"
import { PButton } from "@porsche-design-system/components-react"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

export interface SortCategory {
    label?: string
    items: SortableListItem[]
}

export interface ProductSortContainerConfig {
    listName: string
    fetchSortables: (params: { languageTag: string }) => Promise<SortCategory[] | SortableListItem[]>
    updateSortIndices: (sortIndexUpdate: { sortedKeys: string[] }) => Promise<void>
}

export interface ProductSortContainerProps {
    listRoute: string
}

export const getProductSortContainer = (config: ProductSortContainerConfig) => {
    const ProductSortContainer: FC<ProductSortContainerProps> = (props) => {
        const navigate = useNavigate()
        const toastRef = useRef(useToast())
        const { hasAuthority } = usePdsBootContext()

        const [viewState, setViewState] = useState<ViewState>(ViewState.LOADING)
        const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

        const [sortCategories, setSortCategories] = useState<SortCategory[]>([])

        useEffect(() => {
            const fetchSortables = async () => {
                try {
                    const sortables = await config.fetchSortables({ languageTag: browserLanguage })

                    setSortCategories("items" in sortables[0] ? (sortables as SortCategory[]) : [{ items: sortables as SortableListItem[] }])

                    setViewState(ViewState.CONTENT)
                } catch (e) {
                    setViewState(ViewState.ERROR)
                    if (hasMessage(e)) setErrorMessage(e.message)
                }
            }

            fetchSortables()
        }, [])

        const cancelButton = (
            <PButton key={"cancelButton"} icon={"close"} variant={"tertiary"} onClick={() => navigate(props.listRoute)}>
                Cancel
            </PButton>
        )

        const saveButton = hasAuthority(UserAuthority.MAINTAIN_DATA) ? (
            <PButton
                key={"saveButton"}
                icon={"check"}
                onClick={async () => {
                    setViewState(ViewState.LOADING)

                    const sortedKeys = sortCategories.flatMap((sortCategory) => sortCategory.items.map((sortableListItem) => sortableListItem.key))

                    try {
                        await config.updateSortIndices({ sortedKeys })

                        navigate(props.listRoute)
                        toastRef.current.show("success", "Sort indices updated.")
                    } catch (e) {
                        showErrorToast(toastRef.current, e)
                        setViewState(ViewState.CONTENT)
                    }
                }}
            >
                Apply
            </PButton>
        ) : undefined

        return (
            <>
                <ViewHeadline
                    headline={"Change sorting"}
                    actions={saveButton ? [cancelButton, saveButton] : [cancelButton]}
                    parentCrumbs={[
                        {
                            path: props.listRoute,
                            name: config.listName,
                        },
                    ]}
                />

                <DefaultCardViewContentWrapper>
                    {viewState === ViewState.CONTENT ? (
                        sortCategories.map((sortCategory, index) => {
                            const list = (
                                <SortableList
                                    key={`sortableList_${index}`}
                                    items={sortCategory.items}
                                    onChange={(newItems) => {
                                        const changedSortCategories: SortCategory[] = [
                                            ...sortCategories.slice(0, index),
                                            { label: sortCategory.label, items: newItems },
                                            ...sortCategories.slice(index + 1),
                                        ]
                                        setSortCategories(changedSortCategories)
                                    }}
                                />
                            )

                            return sortCategory.label ? (
                                <WidgetWrapper key={`categoryWrapper_${index}`} label={sortCategory.label}>
                                    {list}
                                </WidgetWrapper>
                            ) : (
                                list
                            )
                        })
                    ) : (
                        <EmptyView viewState={viewState} errorMessage={errorMessage} />
                    )}
                </DefaultCardViewContentWrapper>
            </>
        )
    }

    return ProductSortContainer
}

import { usePdsBootContext } from "../../common/PdsBootContext"
import { browserLanguage, hasMessage } from "../../common/global"
import { numberConverterGroup, useURLSearchParamState } from "../../common/useURLSearchParamState"
import { Crumb } from "../../components/breadcrumb/Breadcrumb"
import { ViewHeadline } from "../../components/headlines/viewheadline/ViewHeadline"
import { SafetyRequestModal } from "../../components/modals/safetyrequestmodal/SafetyRequestModal"
import { TabBar } from "../../components/tabbar/TabBar"
import { BaseInformationDTO, UserAuthority } from "../../generated/pdsapi"
import { useJsonMemorizedValue } from "../../hooks/useJsonMemorizedValue"
import { useToast } from "@finder/ui-kit"
import { PButton, PContentWrapper } from "@porsche-design-system/components-react"
import { color } from "@porsche-design-system/utilities"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"

export interface ProductDetailsContainerConfig<KEY = { key: string }> {
    getBaseInformation: (props: KEY & { languageTag: string }) => Promise<BaseInformationDTO>
    deleteByKey: (props: KEY) => Promise<void>
    tabsConfig: ProductDetailsTabConfig<KEY>[]
}

export interface ProductDetailsTabConfigContentProps<KEY = { key: string }> {
    isDataEditable: boolean
    urlPathParamsPrefix: string
    itemKey: KEY
    reloadDataIndicator: any
    triggerReload: () => void
}

interface ProductDetailsTabConfig<KEY = { key: string }> {
    label: string
    Content: FC<ProductDetailsTabConfigContentProps<KEY>>
}

export interface ProductDetailsContainerProps<KEY = { key: string }> {
    getItemKey: (params: { [p: string]: string }) => KEY
    listPath: string
    parentCrumbs: Crumb[]
}

export const getProductDetailsContainer = <K,>(config: ProductDetailsContainerConfig<K>) => {
    const ProductDetailsContainer: FC<ProductDetailsContainerProps<K>> = ({ getItemKey, listPath, parentCrumbs }) => {
        const [reloadDataIndicator, setReloadDataIndicator] = useState(0)
        const navigate = useNavigate()
        const toastRef = useRef(useToast())
        const { hasAuthority } = usePdsBootContext()
        const itemKey = useJsonMemorizedValue(getItemKey(useParams()))

        const [selectedTab, setSelectedTab] = useURLSearchParamState("selectedTab", 0, numberConverterGroup.required)
        const [hoveredTab, setHoveredTab] = useState<number | undefined>(undefined)

        const [baseInformation, setBaseInformation] = useState<BaseInformationDTO | undefined>(undefined)

        const [hasDeleteRequest, setHasDeleteRequest] = useState<boolean>(false)

        useEffect(() => {
            const fetchBaseInformation = async () => {
                try {
                    const fetchedData = await config.getBaseInformation({ ...itemKey, languageTag: browserLanguage })
                    setBaseInformation(fetchedData)
                } catch (e) {
                    toastRef.current.show("warning", hasMessage(e) ? e.message : "Loading base information failed")
                }
            }

            // noinspection JSIgnoredPromiseFromCall
            fetchBaseInformation()
        }, [itemKey])

        const deleteItem = useMemo(() => {
            const isDataDeletable = baseInformation && baseInformation.isDeletable
            const deleteByKey = isDataDeletable && hasAuthority(UserAuthority.MAINTAIN_DATA) && config.deleteByKey
            return (
                deleteByKey &&
                (async () => {
                    try {
                        await deleteByKey(itemKey)
                        navigate(listPath)
                    } catch (e) {
                        toastRef.current.show("warning", hasMessage(e) ? e.message : "Deleting failed")
                    }
                    setHasDeleteRequest(false)
                })
            )
        }, [baseInformation, hasAuthority, itemKey, navigate, listPath])

        const deleteButton = useMemo(() => {
            return (
                deleteItem && (
                    <PButton key={"deleteButton"} icon={"delete"} onClick={() => setHasDeleteRequest(true)} variant={"tertiary"}>
                        Delete
                    </PButton>
                )
            )
        }, [deleteItem])

        const triggerReload = useCallback(() => setReloadDataIndicator((old) => old + 1), [])

        const { Content } = config.tabsConfig[selectedTab]

        return (
            <>
                <ViewHeadline headline={baseInformation?.headline ?? "..."} parentCrumbs={parentCrumbs} actions={deleteButton ? [deleteButton] : []} />
                <TabBarWrapper>
                    <PContentWrapper>
                        <TabBar
                            tabLabels={config.tabsConfig.map((tab) => tab.label)}
                            hoveredTab={hoveredTab}
                            setHoveredTab={setHoveredTab}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    </PContentWrapper>
                </TabBarWrapper>

                <Content
                    itemKey={itemKey}
                    reloadDataIndicator={reloadDataIndicator}
                    triggerReload={triggerReload}
                    isDataEditable={baseInformation?.isEditable ?? false}
                    urlPathParamsPrefix={`tab${selectedTab}`}
                />

                {deleteItem && (
                    <SafetyRequestModal
                        isOpen={hasDeleteRequest}
                        itemKey={`${JSON.stringify(itemKey)}`}
                        onCancel={() => setHasDeleteRequest(false)}
                        onDelete={deleteItem}
                    />
                )}
            </>
        )
    }

    return ProductDetailsContainer
}

const TabBarWrapper = styled.div`
    background-color: ${color.background.default};
`
